<template>
  <div class="container-fluid">
    <loading :active.sync="isLoading" loader="dots"></loading>

    <div class="page-tabs">
      <template v-for="(item, index) in rows">
        <a
          :key="item.index"
          :class="{ active: currentTab === index }"
          @click.prevent="currentTab = index"
          href="#"
        >
          {{ item.title }}
        </a>
      </template>
    </div>

    <div class="page-tabs-content">
      <ValidationObserver v-slot="{ handleSubmit }">
        <div v-for="(item, index) in rows" :key="item.index">
          <table class="table" v-if="currentTab === index">
            <tbody>
              <tr v-for="data in item.content" :key="data.index">
                <td>
                  {{ data.Item }}
                  <!-- Gmail信件收集履歷 -->
                  <span
                    v-if="data.Item === 'Gmail信件收集履歷'"
                    class="explanation"
                    data-toggle="modal"
                    data-target="#gmailExplanationModal"
                  >
                    ?
                  </span>
                  <!-- O365信件收集履歷 -->
                  <span
                    v-if="data.Item === 'O365信件收集履歷'"
                    class="explanation"
                    data-toggle="modal"
                    data-target="#officeExplanationModal"
                  >
                    ?
                  </span>
                </td>
                <!-- 字串格式 -->
                <td v-if="data.DataType === 'String'">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.Value"
                  />
                </td>
                <!-- 密碼格式 -->
                <td v-if="data.DataType === 'Password'">
                  <input
                    type="password"
                    class="form-control"
                    v-model.trim="data.Value"
                  />
                </td>
                <!-- 數字格式 -->
                <td v-if="data.DataType === 'Int'">
                  <input
                    type="number"
                    min="1"
                    class="form-control"
                    v-model="data.Value"
                  />
                </td>
                <!-- 開關格式 -->
                <td v-if="data.DataType === 'Bool'">
                  <el-radio v-model="data.Value" label="true">
                    {{ i18n("Basic.On") }}
                  </el-radio>
                  <el-radio v-model="data.Value" label="false">
                    {{ i18n("Basic.Off") }}
                  </el-radio>
                </td>
                <!-- 上傳格式 -->
                <td v-if="data.DataType === 'Img'">
                  <el-upload
                    class="upload-demo"
                    action
                    accept=".jpg,.png"
                    :auto-upload="false"
                    :show-file-list="false"
                    list-type="picture"
                    :on-change="uploadFile"
                    v-model="data.Value"
                  >
                    <!-- 首頁背景圖片 -->
                    <el-button
                      v-if="
                        data.Guid === '606190f5-7c43-ec11-876a-00155d014913'
                      "
                      size="small"
                      type="primary"
                      @click="clickUpload('homepage')"
                    >
                      上傳圖片
                    </el-button>
                    <!-- 公司LOGO(適用淺色背景) -->
                    <el-button
                      v-if="
                        data.Guid === 'b27958b4-744b-4655-950e-1d819bf24a2a'
                      "
                      size="small"
                      type="primary"
                      @click="clickUpload('dark')"
                    >
                      上傳圖片
                    </el-button>
                    <!-- 公司LOGO(適用深色背景) -->
                    <el-button
                      v-if="
                        data.Guid === 'b27958b4-744b-4655-950e-1d819bf24a2b'
                      "
                      size="small"
                      type="primary"
                      @click="clickUpload('light')"
                    >
                      上傳圖片
                    </el-button>
                    <div slot="tip" class="el-upload__tip mark">
                      僅能上傳png格式，且大小不超過10MB
                    </div>
                  </el-upload>
                </td>
              </tr>
              <tr>
                <td colspan="2" class="text-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="handleSubmit(editConfig(item))"
                  >
                    {{ i18n("Buttons.Save") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ValidationObserver>
    </div>

    <!-- Gmail信件收集履歷 Modal -->
    <!-- <div
      class="modal fade"
      id="gmailExplanationModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-scrollable modal-lg"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">Gmail串接說明</div>
          <div class="modal-body">
            <img
              style="width: 100%"
              src="@/assets/img/explan/gmail/m_explan_01.jpg"
              alt=""
            />
            <br />
            <br />

            <img
              style="width: 100%"
              src="@/assets/img/explan/gmail/m_explan_02.jpg"
              alt=""
            />
            <br />
            <br />

            <img
              style="width: 100%"
              src="@/assets/img/explan/gmail/m_explan_03.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div> -->

    <!-- O365信件收集履歷 Modal -->
    <!-- <div
      class="modal fade"
      id="officeExplanationModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-scrollable modal-lg"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">Microsoft Graph API</div>
          <div class="modal-body">
            <img
              style="width: 100%"
              src="@/assets/img/explan/office/Microsoft Graph API (Email)-1.jpg"
              alt=""
            />
            <br />
            <br />

            <img
              style="width: 100%"
              src="@/assets/img/explan/office/Microsoft Graph API (Email)-2.jpg"
              alt=""
            />
            <br />
            <br />

            <img
              style="width: 100%"
              src="@/assets/img/explan/office/Microsoft Graph API (Email)-3.jpg"
              alt=""
            />

            <br />
            <br />

            <img
              style="width: 100%"
              src="@/assets/img/explan/office/Microsoft Graph API (Email)-4.jpg"
              alt=""
            />

            <br />
            <br />

            <img
              style="width: 100%"
              src="@/assets/img/explan/office/Microsoft Graph API (Email)-5.jpg"
              alt=""
            />

            <br />
            <br />

            <img
              style="width: 100%"
              src="@/assets/img/explan/office/Microsoft Graph API (Email)-6.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
/* global $ */
export default {
  name: "ParameterSetting",

  data() {
    return {
      currentTab: 0,

      // data
      rows: [],

      // 首頁下方背景圖
      homepageBG: "",
      // 深色LoGo
      logoDark: "",
      // 淺色LoGo
      logoLight: "",

      imgCategory: "dark",
    };
  },

  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },

  methods: {
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },

    // 點擊上傳圖片按鈕
    clickUpload(category) {
      this.imgCategory = category;
    },

    // 取得參數 API
    getConfig() {
      const vm = this;
      const api = `${window.BaseUrl.api}/BasicInformation/showableconfig`;

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);

            let arr = [];
            arr = Object.keys(response.data);
            for (let i = 0; i < arr.length; ++i) {
              vm.rows.push({
                title: arr[i],
                content: response.data[arr[i]],
              });
            }
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    // 上傳檔案 API
    uploadFile(file) {
      const vm = this;
      const api = `${window.BaseUrl.api}/FileUpload/UploadFile`;

      vm.$store.dispatch("updateLoading", true);

      let fileGroupGuid;

      switch (vm.imgCategory) {
        case "homepage":
          fileGroupGuid = vm.homepageBG;
          break;
        case "dark":
          fileGroupGuid = vm.logoDark;
          break;
        case "light":
          fileGroupGuid = vm.logoLight;
          break;
      }

      let fd = new FormData();
      fd.append("File", file.raw);
      fd.append("FileType", file.raw.type);
      fd.append("FileGroupGuid", fileGroupGuid);
      fd.append("CreateUser", null);

      vm.$store.dispatch("updateLoading", true);

      vm.$http
        .post(api, fd, { headers: { "Content-Type": "multipart/form-data" } })
        .then((response) => {
          switch (vm.imgCategory) {
            case "homepage":
              vm.homepageBG = response.data;
              break;
            case "dark":
              vm.logoDark = response.data;
              break;
            case "light":
              vm.logoLight = response.data;
              break;
          }
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "成功",
            message: "上傳成功",
            type: "success",
            duration: 3500,
          });
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "上傳失敗",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },

    // 修改參數
    editConfig(rows) {
      const vm = this;
      const api = `${window.BaseUrl.api}/BasicInformation/config/batch`;

      const guid = [];
      const value = [];

      rows.content.forEach((item) => {
        guid.push(item.Guid);
        if (item.Guid === "606190f5-7c43-ec11-876a-00155d014913") {
          // 首頁下方背景圖
          vm.homepageBG === "" ? value.push(item.Value) : value.push(vm.homepageBG);
        } else if (item.Guid === "b27958b4-744b-4655-950e-1d819bf24a2a") {
          // 公司LOGO(適用淺色背景)
          vm.logoDark === "" ? value.push(item.Value) : value.push(vm.logoDark);
        } else if (item.Guid === "b27958b4-744b-4655-950e-1d819bf24a2b") {
          // 公司LOGO(適用深色背景)
          vm.logoLight === ""
            ? value.push(item.Value)
            : value.push(vm.logoLight);
        } else {
          value.push(item.Value);
        }
      });

      const data = {
        Guid: guid,
        Value: value,
        UpdateUser: vm.$cookies.get("userName"),
      };

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .put(api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.fileGroupGuid = "";
            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "成功",
              message: "編輯成功",
              type: "success",
              duration: 2000,
            });
          }
        })
        .catch((error) => {
          vm.fileGroupGuid = "";
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },
  },

  created() {
    this.getConfig();
  },

  destroyed() {
    $(".modal").modal("hide");
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
  },
};
</script>
